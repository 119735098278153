<template>
  <div class="accounts">
    <div class="container mt-3 mt-sm-5">
      <nav v-if="step !== 3">
        <ul class="nav nav-tabs-material border-bottom d-flex flex-nowrap text-center">
          <li class="nav-item">
            <router-link :to="{name: 'SelectSchool'}" class="nav-link" :class="{'active': isRoute('SelectSchool')}">
              <div class="d-md-none">
                <div class="small">
                  Select your school
                </div>
                <font-awesome-icon v-if="step > 1" icon="check-circle" />
                <div v-else>
                  &#9312;
                </div>
              </div>
              <span class="d-none d-md-block font-weight-normal">
                <font-awesome-icon v-if="step > 1 && selectedSchool" class="float-left mt-2" icon="check-circle" />
                <div v-else class="float-left sized-number">
                  &#9312;
                </div>
                <div>
                  Select your school
                </div>
              </span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'StudentAccount'}" class="nav-link" :class="{'active': (isRoute('StudentAccount') || isRoute('LTI-StudentAccount')), 'disabled': (!selectedSchool || (user && user.is_logged_in))}">
              <div class="d-md-none">
                <div class="small">
                  Account details
                </div>
                <font-awesome-icon v-if="step > 2" icon="check-circle" />
                <div v-else>
                  &#9313;
                </div>
              </div>
              <span class="d-none d-md-block font-weight-normal">
                <font-awesome-icon v-if="step > 2" class="float-left mt-2" icon="check-circle" />
                <div v-else class="float-left sized-number">&#9313; </div>
                <div>Account details</div>
              </span>
            </router-link>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" :class="{'active' : isRoute('StudentPayment'), 'disabled': user}" class="nav-link"
               @click="goToPayment()">
              <div class="d-md-none">
                <div class="small">Payment information</div>
                <div>
                  &#9314;
                </div>
              </div>
              <span class="d-none d-md-block font-weight-normal">
                <div class="float-left sized-number">
                  &#9314;
                </div>
                <div>Payment information</div>
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <transition v-if="user" :name="transitionName" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import analytics from '../../../../../shared/js/analytics.js';
import zendesk from '../../../../../shared/js/zendesk.js';
import PaymentService from '@/utils/payment';

export default {
  name: 'StudentStore',
  mixins: [zendesk],
  data () {
    return {
      collapseOpen: false,
      transitionName: '',
      activeLoader: null
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.selectedSchool && to.name !== 'SelectSchool' && (to.query.schoolbypass && !to.query.schoolbypass) ){
        console.log('No school selected!');
        next({name: 'SelectSchool',  query: to.query});
      }
    });
  },

  computed: {
    isRoute () {
      return function (name) {
        return name === this.$route.name;
      };
    },
    selectedSchool () {
      return this.$store.getters['studentStore/getSelectedSchool'];
    },
    step () {
      return this.$store.getters['studentStore/getStep'];
    }
  },
  watch:{
    selectedSchool (val) {
      if (!this.selectedSchool && this.$route.name !== 'SelectSchool'){
        console.log('!!!! - You need to select a school.');
        this.$router.push({name: 'SelectSchool'});
      }
    }
  },
  created () {
    this.init();
  },
  methods: {
    init () {
      this.activeLoader = this.$loading.show();
      PaymentService.setStripeLibrary();
      this.setTransition();
      this.activeLoader.hide();
    },
    /**
     * Set transitions between states in student store
     *
     * @return {Void}
     */
    setTransition () {
      this.$router.beforeEach((to, from, next) => {
        if (to.meta && from.meta) {
          let transitionName = to.meta.transitionName || from.meta.transitionName;
          if (transitionName === 'slide') {
            transitionName = to.meta.transitionOrder < from.meta.transitionOrder ? 'slide-right' : 'slide-left';
          }
          this.transitionName = transitionName || 'fade';
        }
        next();
      });
    },
    goToPayment () {
      this.$router.push({name: 'StudentPayment'}).catch(err => {});
    }
  }
};
</script>

<style scoped>

.sized-number {
  font-size: 24px;
}
</style>
